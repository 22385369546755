var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  var $waitListText = $('.js-add-to-waitlist', '.js-product');
  /**
   * Helper function to toggle button visibility
   */
  var _toggleButtonVis = function ($button, show) {
    show = typeof show !== 'undefined' ? show : false;
    if (show) {
      $button.removeClass('hidden');
    } else {
      $button.addClass('hidden');
    }
  };
  var _hideButtons = function ($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList) {
    $btnAddToBag.addClass('hidden');
    $btnSoldOut.addClass('hidden');
    $btnOutStock.addClass('hidden');
    $btnComingSoon.addClass('hidden');
    $btnWaitList.addClass('hidden');
  };
  /**
   * Helper function to consoldate sku data logic for all event handlers
   */
  var _processSkuData = function (skuData, $btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList) {
    if (!skuData) {
      return;
    }
    var $productDetails = $btnWaitList.closest('.js-product');
    var $productSelectBox = $('.js-product-selectbox', $productDetails);

    if (skuData.INVENTORY_STATUS) {
      // Initially all buttons are hidden and only Add to bag is shown(necessary for sku changes)
      _hideButtons($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
      _toggleButtonVis($btnAddToBag, true);
      $productSelectBox.removeClass('dropdown--disabled');
      // Set appropriate button states once default state is set.
      if (skuData.INVENTORY_STATUS - 0 === 2) {
        // Temporarily Out of Stock
        if (Drupal.settings.common.has_waitlist_tos) {
          _hideButtons($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
          _toggleButtonVis($btnWaitList, true);
        } else {
          _hideButtons($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
          _toggleButtonVis($btnAddToBag, true);
          _toggleButtonVis($btnOutStock, true);
          $btnAddToBag.parent().next().css('vertical-align', 'top');
        }
      }
      if (skuData.INVENTORY_STATUS - 0 === 3) {
        // Coming Soon
        if (Drupal.settings.common.enable_waitlist_coming_soon) {
          _hideButtons($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
          _toggleButtonVis($btnWaitList, true);
        } else {
          _hideButtons($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
          _toggleButtonVis($btnComingSoon, true);
        }
        $productSelectBox.addClass('dropdown--disabled');
      }
      if (skuData.INVENTORY_STATUS - 0 === 7) {
        // Sold out
        _hideButtons($btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
        // If INV STATUS is sold out and LIFE OF PRODUCT is Basic Reorder then display only Notify Me CTA
        $productSelectBox.addClass('dropdown--disabled');
        if (
          skuData.LIFE_OF_PRODUCT - 0 === 2 &&
          $btnWaitList.length > 0 &&
          !Drupal.settings.common.disable_waitlist_soldout
        ) {
          _toggleButtonVis($btnWaitList, true);
          $waitListText.hide();
        } else {
          _toggleButtonVis($btnSoldOut, true);
        }
      }
    }
  };

  $('.js-wait-list', '.js-product')
    .once()
    .on('click', function () {
      var skuBaseId = $(this).attr('data-sku-base-id');
      var skuData = prodcat.data.getSku(skuBaseId);

      $(document).trigger('product:waitlist:overlay', skuData);

      return false;
    });

  /**
   * Respond to inventory status updates
   */
  $('.js-sold-out, .js-out-of-stock, .js-coming-soon').on(
    'inv_status_data:updated inv_status_data:finished',
    function () {
      var $btnCurrent = $(this);
      var $btnAddToBag = !!$btnCurrent.length ? $btnCurrent.siblings('.js-add-to-cart') : $();
      var $btnWaitList = !!$btnCurrent.length ? $btnCurrent.siblings('.js-wait-list') : $();
      var $btnSoldOut;
      var $btnOutStock;
      var $btnComingSoon;
      var skuBaseId;
      var skuData;

      if ($btnCurrent.hasClass('js-sold-out')) {
        $btnSoldOut = $btnCurrent;
        $btnOutStock = $btnCurrent.siblings('.js-out-of-stock');
        $btnComingSoon = $btnCurrent.siblings('.js-coming-soon');
      }
      if ($btnCurrent.hasClass('js-out-of-stock')) {
        $btnSoldOut = $btnCurrent.siblings('.js-sold-out');
        $btnOutStock = $btnCurrent;
        $btnComingSoon = $btnCurrent.siblings('.js-coming-soon');
      }
      if ($btnCurrent.hasClass('js-coming-soon')) {
        $btnSoldOut = $btnCurrent.siblings('.js-sold-out');
        $btnOutStock = $btnCurrent.siblings('.js-out-of-stock');
        $btnComingSoon = $btnCurrent;
      }

      skuBaseId = $btnCurrent.data('sku-base-id');
      skuData = prodcat.data.getSku(skuBaseId);
      _processSkuData(skuData, $btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
    }
  );

  /**
   * Respond to sku select events
   */
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var $btnAddToBag = $('.js-add-to-cart', $product);
    var $btnSoldOut = $('.js-sold-out', $product);
    var $btnOutStock = $('.js-out-of-stock', $product);
    var $btnComingSoon = $('.js-coming-soon', $product);
    var $btnWaitList = $('.js-wait-list', $product);
    var skuData = prodcat.data.getSku(skuBaseId);

    _processSkuData(skuData, $btnAddToBag, $btnSoldOut, $btnOutStock, $btnComingSoon, $btnWaitList);
  });
})(jQuery);
